import { useEffect } from "react";
import { create } from "zustand";

import { parseDate } from "@puzzle/utils";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { useRippling } from "./useRippling";
import { IntegrationType } from "graphql/types";

const EXPIRATION_DATE = parseDate("2025-02-28").toDate("America/New_York");
const NOW = new Date();
const IS_EXPIRED = NOW > EXPIRATION_DATE;

type ExpireLegacyRipplingState = {
  connectingRippling: boolean;
  isRipplingLegacyModalOpen: boolean;
  setIsRipplingLegacyModalOpen: (isOpen: boolean) => void;
  setConnectingRippling: (connecting: boolean) => void;
};

const useExpireLegacyRipplingStore = create<ExpireLegacyRipplingState>((set) => ({
  connectingRippling: false,
  isRipplingLegacyModalOpen: false,
  setIsRipplingLegacyModalOpen: (isOpen: boolean) => set({ isRipplingLegacyModalOpen: isOpen }),
  setConnectingRippling: (connecting: boolean) => set({ connectingRippling: connecting }),
}));

export const useExpireLegacyRippling = () => {
  const {
    connectingRippling,
    isRipplingLegacyModalOpen,
    setIsRipplingLegacyModalOpen,
    setConnectingRippling,
  } = useExpireLegacyRipplingStore();
  const { company } = useActiveCompany<true>();
  const { disconnect: disconnectRippling } = useRippling({
    companyId: company.id,
    integrationConnectionId: company?.integrationConnections?.find(
      (c) => c.type === IntegrationType.Rippling
    )?.id,
  });

  const isRipplingLegacyApi = company?.features.ripplingLegacyApi && !connectingRippling;

  const expMessage = IS_EXPIRED
    ? "The Rippling Legacy Integration disconnected on Feb 28, 2025. Reconnect to continue syncing without interruption."
    : "The Rippling Legacy Integration will disconnect on Feb 28, 2025. Reconnect to continue syncing without interruption.";

  useEffect(() => {
    if (isRipplingLegacyApi && IS_EXPIRED) {
      disconnectRippling?.();
    }
    setIsRipplingLegacyModalOpen(isRipplingLegacyApi);
  }, [isRipplingLegacyApi]);

  return {
    expMessage,
    isExpired: IS_EXPIRED,
    isRipplingLegacyApi,
    isRipplingLegacyModalOpen,
    setIsRipplingLegacyModalOpen,
    setConnectingRippling,
  };
};
