import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { usePlansPricingModal } from "../PlansPricingModal/usePlansPricingModal";
import { useState } from "react";
import { useSkipTrialStore } from "./useSkipTrialStore";

export const useSkipTrial = () => {
  const { setIsCheckingSkipTrial, hideSkipTrialConfirmationDialog } = useSkipTrialStore();
  const { updateCompany: _updateCompany, company } = useActiveCompany();
  const [isClosingSkipTrailModal, setIsClosingSkipTrailModal] = useState(false);
  const {
    showUpgradeModal: _showUpgradeModal,
    isOnFreeTrial,
    isOnPaidPlan,
    hideUpgradeModal,
  } = usePlansPricingModal();

  const [isSkipTrialModalOpen, setIsSkipTrialModalOpen] = useState(() => {
    const isEligibleForOneDolar = isOnFreeTrial && !isOnPaidPlan;
    const hasSeenOneDollarModal = company && company.attributes?.skippedTrial !== null;
    return isEligibleForOneDolar && !hasSeenOneDollarModal;
  });

  const checkSkipTrail = async () => {
    setIsCheckingSkipTrial(true);
    await _showUpgradeModal();
    setIsSkipTrialModalOpen(false);
  };

  const discardSkipTrial = async () => {
    setIsClosingSkipTrailModal(true);
    await _updateCompany({
      attributes: {
        skippedTrial: false,
      },
    });
    setIsClosingSkipTrailModal(false);
    setIsSkipTrialModalOpen(false);
  };

  const confirmDiscardSkipTrial = async () => {
    setIsCheckingSkipTrial(false);
    await discardSkipTrial();
    hideSkipTrialConfirmationDialog();
    hideUpgradeModal(true);
  };

  return {
    isClosingSkipTrailModal,
    isSkipTrialModalOpen,
    checkSkipTrail,
    discardSkipTrial,
    confirmDiscardSkipTrial,
  };
};
