import React from "react";
import { getDaysLeftOnFreeTrial } from "./utils";
import { ColorfulCrown } from "@puzzle/icons";
import { Stack, Button, Text } from "ve";
import { vars } from "@puzzle/theme";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import { freeTrialExpiringStyle } from "./trailInfoPanel.css";
import { getFreeTrialMessage } from "components/settings/Billing/util";
import { usePlansPricingModal } from "components/monetization/PlansPricingModal/usePlansPricingModal";
import { DATA_TEST_IDS } from "../constants";
import Analytics from "lib/analytics/analytics";

export function TrialInfoPanel() {
  const { company } = useActiveCompany<true>();
  const { showUpgradeModal } = usePlansPricingModal();
  const daysLeftOnFreeTrial = getDaysLeftOnFreeTrial(company.puzzleSubscriptionState);
  if (daysLeftOnFreeTrial === null) {
    return null;
  }

  const handleViewPlans = () => {
    Analytics.sideNavViewPlansClicked();
    showUpgradeModal();
  };

  return (
    <div className={freeTrialExpiringStyle}>
      <Stack direction="horizontal" css={{ alignItems: "center" }} gap={vars.space["1"]}>
        <ColorfulCrown />
        <Text>{getFreeTrialMessage(daysLeftOnFreeTrial)}</Text>
      </Stack>
      <Button
        data-testid={DATA_TEST_IDS.VIEW_PLANS_BUTTON}
        fullWidth
        variant="gold"
        onClick={handleViewPlans}
      >
        View plans
      </Button>
    </div>
  );
}
