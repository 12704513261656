import { styled, colors } from "@puzzle/theme";
import rgba from "polished/lib/color/rgba";

export const MenuButton = styled("button", {
  appearance: "none",
  background: "none",
  border: "none",
  outline: "none",
  cursor: "pointer",
  borderRadius: "$1",

  display: "flex",
  flexDirection: "row",

  "& *:last-child": {
    marginLeft: "$1h",
  },

  "& *:only-child": {
    marginLeft: "0",
  },

  "> div": {
    transition: "border 0.2s",
    border: "1px solid transparent",
  },

  "&:hover, &[data-state='open']": {
    "> div": {
      borderColor: rgba(colors.gray100, 0.32),
    },
  },

  variants: {
    minimized: {
      false: {
        padding: "6px $1",
      },
      true: {
        padding: "$0h",
      },
    },
    isEmpty: {
      true: {
        "> div": {
          transition: "background 0.2s",
        },
        "&:hover, &[data-state='open']": {
          "> div": {
            backgroundColor: "$gray100",
          },
        },
      },
    },
  },
});
