import React, { useState } from "react";
import { Command } from "cmdk";
import {
  cmdKContainerStyles,
  cmdKFooterStyles,
  cmdKItemIconContainerStyles,
  cmdKItemLabelStyles,
  cmdKOverlayStyle,
} from "./cmdk.css";
import { ChevronRight, GoTo } from "@puzzle/icons";
import { Text } from "ve";
import { keysContainer } from "../ShortcutsModal/shortcutsModal.css";
import { Kbd } from "ve/Kbd/Kbd";
import { useRouter } from "next/router";
import { getClosestStaticRoute, getRouteInfo, isDynamicRoute } from "lib/routes";
import { useAppRouter } from "lib/useAppRouter";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import type { InactiveCompanyFragment } from "components/companies/graphql.generated";
import { useLaunchpadStore } from "components/launchpad/launchpadStore";
import Analytics from "lib/analytics/analytics";
import { commandScore } from "./utils";
import { useCmdkStore } from "./cmdkStore";
import { SHORTCUTS_GROUPS } from "./shortcuts/shortcuts";
import { useShortcutActions } from "../ShortcutActionsProvider";
import { Shortcut } from "./shortcuts/types";
import CmdKLabel from "./Label";
import { MODAL_ROOT_ID } from "constants/ui";
import { IS_CLIENT } from "lib/config";
// Keys to ignore and render the word entirely
// outside a kbd
const IGNORED_KEY = ["then", "or"];

const shouldIgnoreKey = (key: string) => {
  return IGNORED_KEY.includes(key);
};

const Item = ({
  icon,
  description,
  hotkey,
  keywords,
  onSelect,
}: Shortcut & { onSelect: () => void }) => {
  return (
    <Command.Item onSelect={onSelect} keywords={keywords}>
      <div className={cmdKItemIconContainerStyles}>{icon ? icon : <ChevronRight size={12} />}</div>
      <Text className={cmdKItemLabelStyles}>{description}</Text>
      <div className={keysContainer}>
        {hotkey?.keys.map((key, keyIndex) =>
          shouldIgnoreKey(key) ? key : <Kbd key={`${description}-${keyIndex}`}>{key}</Kbd>
        )}
      </div>
    </Command.Item>
  );
};

export const CmdK = () => {
  const { companies, setActiveCompanyId } = useActiveCompany<true>();
  const router = useRouter();
  const { getCurrentRoute } = useAppRouter();
  const { isCmdkOpen: open, setIsCmdkOpen: setOpen, isTargetTypeAllowed } = useCmdkStore();
  const Actions = useShortcutActions();
  const { isLaunchpadOverlayVisible, hideLaunchpadOverlay } = useLaunchpadStore();

  const modalRoot = (IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null) || undefined;

  // hacky way to avoid selecting the first one on open
  const DEFAULT_SELECTED_SHORTCUT = "none";
  const [selectedShortcut, setSelectedShortcut] = useState<string>(DEFAULT_SELECTED_SHORTCUT);
  const [searchInput, setSearchInput] = useState<string>("");

  const handleSelect = (onSelect: keyof typeof Actions) => {
    if (Actions[onSelect]) Actions[onSelect]?.();
  };

  // Copy paste from Company Menu, but it might be helpful
  // to abstract this into a custom hook.
  const setCompany = (company: InactiveCompanyFragment) => {
    Analytics.companyChanged({
      companyId: company.id,
    });
    setActiveCompanyId(company.id);
    if (isDynamicRoute(router.pathname)) {
      router.replace(getClosestStaticRoute(router.pathname));
    }
  };

  const sortedCompanies = [...companies].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const trackSearch = () => {
    // We don't want to track every keystroke, only the final search term the user entered.
    if (searchInput !== "") {
      Analytics.cmdkModalSearchPerformed({
        searchTerm: searchInput,
      });
      setSearchInput("");
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      trackSearch();
      setSelectedShortcut(DEFAULT_SELECTED_SHORTCUT);
      Analytics.cmdkModalClosed();
    }
    Analytics.cmdkModalOpened();
    setOpen(isOpen); // Necessary for the modal to open or close as expected
  };

  const trackClick = (description: string) => {
    trackSearch();
    Analytics.cmdkModalItemSelected({
      description,
    });
    setSelectedShortcut("none");
  };

  // Given the current route, return the title of the page.
  // Gets the first part of the pathname and returns a title.
  // Example: /inbox/updates -> "inbox"
  const routeInfo = getRouteInfo(getCurrentRoute());

  // If the group has a urlPattern and it matches the current URL
  // Reorder the group to be first in the list
  const reorderedShortcutGroups = SHORTCUTS_GROUPS.map((group) => {
    const groupUrlPattern = group.urlPattern;
    if (groupUrlPattern && groupUrlPattern.test(window.location.pathname)) {
      return { ...group, order: 1 };
    }
    return { ...group, order: 2 };
  }).sort((a, b) => a.order - b.order);

  return (
    <Command.Dialog
      open={open}
      onOpenChange={handleOpenChange}
      value={selectedShortcut}
      onValueChange={setSelectedShortcut}
      filter={commandScore}
      container={modalRoot}
    >
      <div className={cmdKOverlayStyle} onClick={() => handleOpenChange(false)} />
      <div className={cmdKContainerStyles}>
        {/* This is a label that displays over the search input, showing info regarding what page you are on. */}
        {routeInfo && <CmdKLabel routeInfo={routeInfo} />}

        <Command.Input
          placeholder="Type a command or search"
          value={searchInput}
          onValueChange={(newInputValue) => {
            setSearchInput(newInputValue);
          }}
        />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>

          {reorderedShortcutGroups.map((group) => {
            // If urlPattern exists for a shortcut,
            // Remove it if the urlPattern does not match the current URL
            const filteredShortcuts = group.shortcuts.filter((shortcut) => {
              // If the shortcut has a TargetType, check that TargetType's conditions
              if (shortcut.targetType && shortcut.urlPattern) {
                return (
                  // urlPattern matches the current URL
                  isTargetTypeAllowed(shortcut.targetType) &&
                  shortcut.urlPattern.test(window.location.pathname)
                );
              }
              // If the shortcut does not have a TargetType, but does have a urlPattern, check the URL pattern
              if (!shortcut.targetType && shortcut.urlPattern) {
                return shortcut.urlPattern.test(window.location.pathname);
              }
              // If the shortcut has neither a TargetType nor a URL pattern, allow it
              else if (!shortcut.urlPattern && !shortcut.targetType) {
                return true;
              }
              return false;
            });
            // If a group has no shortcuts after filtering for the page we're on, don't render the group
            if (filteredShortcuts.length === 0) return null;
            return (
              <Command.Group heading={group.title} key={`kbd-${group.id}`}>
                {group.shortcuts.map((shortcut) => (
                  <Item
                    icon={shortcut.icon}
                    description={shortcut.description}
                    hotkey={shortcut.hotkey}
                    key={`kbd-${group.id}-${shortcut.id}`}
                    keywords={shortcut.keywords}
                    onSelect={() => {
                      if (shortcut.actionOnSelect) {
                        handleSelect(shortcut.actionOnSelect as keyof typeof Actions);
                      }
                      if (shortcut.route) {
                        router.push(shortcut.route);
                      }
                      setOpen(false);
                      trackClick(shortcut.description);
                      if (isLaunchpadOverlayVisible) {
                        hideLaunchpadOverlay();
                      }
                    }}
                  />
                ))}
              </Command.Group>
            );
          })}
          {/*

            The "Switch Company" shortcuts get special treatment.
            They need the dynamic list of companies. So they are not static shortcut links.

          */}
          {companies.length > 1 && (
            <Command.Group heading="Switch company">
              {sortedCompanies.map((company) => (
                <Item
                  key={`switch-company-${company.id}`}
                  icon={<GoTo size={12} />}
                  description={company.name}
                  onSelect={() => {
                    setCompany(company);
                    trackClick("Switch company");
                  }}
                />
              ))}
            </Command.Group>
          )}
        </Command.List>
        <div className={cmdKFooterStyles}>
          <div className={keysContainer}>
            <Kbd>↑</Kbd>
            <Kbd>↓</Kbd>
            <div>Navigate items</div>
          </div>
          <div className={keysContainer}>
            <Kbd>Esc</Kbd>
            <div>Close</div>
          </div>
        </div>
      </div>
    </Command.Dialog>
  );
};
