import React, { createContext, useContext, ReactNode } from "react";
import { ActionDict } from "./cmdk/shortcuts/types";
import { useShortcutsModal } from "./ShortcutsModal/useShortcutsModal";
import { useIntercom } from "react-use-intercom";
import { useCmdkStore } from "./cmdk/cmdkStore";
import { useBulkRecategorizeStore } from "components/dashboard/Transactions/hooks/useBulkRecategorizeStore";
import { useLaunchpadStore } from "components/launchpad/launchpadStore";
import { useAppRouter } from "lib/useAppRouter";

type ActionsContextProps = {
  Actions: ActionDict;
};

const ActionsContext = createContext<ActionsContextProps | undefined>(undefined);

export const ShortcutActionsProvider = ({ children }: { children: ReactNode }) => {
  const { show: showIntercom } = useIntercom();
  const { showLaunchpadOverlay } = useLaunchpadStore();
  const { toggleShortcutsModal } = useShortcutsModal();
  const { toggleCmdk } = useCmdkStore();
  const { logout } = useAppRouter();
  const { setIsOpen: setIsBulkRecategorizeOpen } = useBulkRecategorizeStore();

  const Actions: ActionDict = {
    OPEN_SHORTCUTS: () => {
      toggleShortcutsModal();
    },
    OPEN_HELP_WINDOW: () => {
      window.open("https://help.puzzle.io", "_blank");
    },
    SHOW_INTERCOM: (e) => {
      e?.preventDefault();
      showIntercom();
    },
    SHOW_LAUNCHPAD_OVERLAY: (e) => {
      e?.preventDefault();
      showLaunchpadOverlay();
    },
    TOGGLE_CMDK_MODAL: (e) => {
      e?.preventDefault();
      toggleCmdk();
    },
    LOGOUT: () => {
      logout();
    },
    OPEN_BULK_RECATEGORIZE_MODAL: () => {
      setIsBulkRecategorizeOpen(true);
    },
  };

  return <ActionsContext.Provider value={{ Actions }}>{children}</ActionsContext.Provider>;
};

export const useShortcutActions = () => {
  const context = useContext(ActionsContext);
  if (!context) {
    throw new Error("useShortcutActions must be used in a ShortcutActionsProvider");
  }
  return context.Actions;
};
