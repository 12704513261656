export const DATA_TEST_IDS = {
  CONTAINER: "sidebar_container",
  MINIMIZER: "sidebar-minimizer",
  LOGO: "sidebar-logo",
  PROFILE_MENU: "ProfileMenu",
  INBOX: "sidebar-inbox-link",
  DASHBOARD: "sidebar-dashboard-link",
  CHECKLIST: "sidebar-checklist-link",
  TRANSACTIONS: "sidebar-transactions-link",
  ANALYSIS: "sidebar-analysis-link",
  SPENDING: "sidebar-spending-link",
  PEOPLE: "sidebar-people-link",
  ANALYSIS_REVENUE: "sidebar-analysis-revenue-link",
  AI_LABS: "sidebar-ai-labs-link",
  ACCOUNTING: "sidebar-accounting-link",
  LOGOUT: "sidebar-logout-link",
  GENERAL_LEDGER: "sidebar-general-ledger-link",
  CHART_OF_ACCOUNTS: "sidebar-chart-of-accounts-link",
  RECONCILIATIONS: "sidebar-reconciliations-link",
  MANUAL_JOURNALS: "sidebar-manual-journals-link",
  FIXED_ASSETS: "sidebar-fixed-assets-link",
  AUTOMATION_SETTINGS: "sidebar-automation-settings-link",
  REVENUE: "sidebar-revenue-link",
  INVOICES: "sidebar-invoices-link",
  CUSTOMERS: "sidebar-customers-link",
  PRODUCTS: "sidebar-products-link",
  REVENUE_RECOGNITION: "sidebar-revenue-recognition-link",
  ACCRUAL_REVENUE: "sidebar-accrual-revenue-link",
  AR_AGING: "sidebar-ar-aging-link",
  INVOICE_PAYMENTS: "sidebar-invoice-payments-link",
  EXPENSES: "sidebar-expenses-link",
  VENDORS: "sidebar-vendors-link",
  BILLS: "sidebar-bills-link",
  PREPAID_EXPENSES: "sidebar-prepaid-expenses-link",
  AP_AGING: "sidebar-ap-aging-link",
  BILL_PAYMENTS: "sidebar-bill-payments-link",
  PAYROLL: "sidebar-payroll-link",
  DOWNLOADS: "sidebar-downloads-link",
  INTEGRATIONS: "sidebar-integrations-link",
  SETTINGS: "sidebar-settings-link",
  COMPANY: "sidebar-company-link",
  BILLING: "sidebar-billing-link",
  SETTINGS_ACCOUNTING: "sidebar-settings-accounting-link",
  NOTIFICATIONS: "sidebar-notifications-link",
  VIEW_PLANS_BUTTON: "view-plans-button",
  CMD_ITEM: "cmd-item-element",
};
