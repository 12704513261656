import React from "react";

import { Exclamation } from "@puzzle/icons";
import { SiteStatusMessage, Text } from "@puzzle/ui";

import { Link } from "components/common/Link";
import { useExpensesExceededFeatureGate } from "./useExpensesExceededFeatureGate";
import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import useSelf from "components/users/useSelf";
import links from "lib/links";
import { Box } from "@puzzle/ve";
import { usePlansPricingModal } from "components/monetization/PlansPricingModal/usePlansPricingModal";

const SETTING_KEY = "expenses-exceeded-status-message-seen";
export const ExpensesExceededStatusMessage = () => {
  const { isExpensesExceeded } = useExpensesExceededFeatureGate();
  const { showUpgradeModal } = usePlansPricingModal();
  const { messageSettings } = useSelf();
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  if (!isExpensesExceeded || messageSettings[SETTING_KEY]) {
    return null;
  }

  const markAsSeen = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY,
          value: true,
        },
      },
    });
  };

  return (
    <SiteStatusMessage
      type="warning"
      onClose={() => {
        markAsSeen();
      }}
    >
      <Box css={{ display: "flex", flexDirection: "row" }}>
        <Exclamation size={14} css={{ marginRight: "$1" }} />
        <Text>
          You've exceeded the monthly expense allowance of $15,000 for the Formation plan.
        </Text>
        <Link
          onClick={() => {
            showUpgradeModal();
          }}
          css={{
            marginLeft: "$0h",
            marginRight: "$0h",
            color: "$white",
            textDecoration: "underline",
          }}
        >
          Upgrade
        </Link>
        <Text>or</Text>
        <Link
          css={{ marginLeft: "$0h", textDecoration: "underline" }}
          href={links.pricingFaq}
          target="_blank"
        >
          learn more.
        </Link>
      </Box>
    </SiteStatusMessage>
  );
};
