import React from "react";

import { SiteStatusMessage } from "@puzzle/ui";

import { VolumeLevel, useHighVolumeCompany } from "components/companies/useHighVolumeCompany";

export const HighTransactionVolumeBanner = () => {
  const { volumeLevel } = useHighVolumeCompany();

  if (volumeLevel === VolumeLevel.LOW) return null;

  return (
    <SiteStatusMessage type="warning">
      {volumeLevel === VolumeLevel.MODERATE
        ? "Whoa! Looks like you have a high volume of transactions and/or customers. Your revenue data may take some time to load. Hang tight."
        : "Looks like you have a high volume of transactions and/or customers to ingest. Unfortunately, we’re currently unable to support such high volumes. Please send a message to our Customer Success team so we can better understand your needs"}
    </SiteStatusMessage>
  );
};
