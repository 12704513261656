import { create } from "zustand";

type ShortcutsModalStoreProps = {
  isVisible: boolean;
  showShorcutsModal: () => void;
  toggleShortcutsModal: () => void;
  hideShortcutsModal: () => void;
};

export const useShortcutsModal = create<ShortcutsModalStoreProps>((set) => ({
  isVisible: false,
  showShorcutsModal: () =>
    set((state: ShortcutsModalStoreProps) => ({ ...state, isVisible: true })),
  toggleShortcutsModal: () =>
    set((state: ShortcutsModalStoreProps) => ({ ...state, isVisible: !state.isVisible })),
  hideShortcutsModal: () =>
    set((state: ShortcutsModalStoreProps) => ({ ...state, isVisible: false })),
}));
