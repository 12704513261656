import { QUESTIONS, type Question } from "./types";

export const AI_LABS_SECTION_TITLE = "Puzzle AI";
export const DEV_MODE = false;
export const DEFAULT_PROMPT = "month_end_review";
export const FOLLOWUP_PROMPT = "month_end_review_followup";
export const BOARD_MEETING_PREP_PROMPT = "wouter_born_board_prep";
export const BOARD_MEETING_PREP_ID = "7";
export const API_ID = "6";
export const API_EXPLORE_LINK =
  "https://puzzle-financial.stoplight.io/docs/puzzle-accounting-api/fslanc6eqatsw-introduction";

export const QUESTIONS_LIST: Question[] = [
  {
    id: "1",
    title: QUESTIONS.REVIEW_MONTH_END_CLOSE,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the month-end accuracy review for ${formattedDate}:`,
    enabled: true,
    promptName: DEFAULT_PROMPT,
  },
  {
    id: "2",
    title: QUESTIONS.BUSINESS_STATUS_ELON,
    responseLabel: (formattedDate: string) =>
      `Here’s how Elon Mask would describe your business for ${formattedDate}. I’ll keep this short and to the point:`,
    enabled: true,
    promptName: "investor_update_harsh",
  },
  {
    id: "3",
    title: QUESTIONS.BUSINESS_STATUS_INVESTOR,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the business status for ${formattedDate}:`,
    enabled: true,
    promptName: "investor_update_balanced",
  },
  {
    id: "4",
    title: QUESTIONS.BUSINESS_STATUS_FRIENDLY,
    responseLabel: (formattedDate: string) =>
      `Here’s the summary of the business status for ${formattedDate}:`,
    enabled: true,
    promptName: "investor_update_kind",
  },
  {
    id: "5",
    title: QUESTIONS.VALIDATE_BANK_RECONCILIATIONS,
    responseLabel: (formattedDate: string) =>
      `Here’s the validated reconciliations for ${formattedDate}:`,
    enabled: false,
    promptName: "upcoming",
  },
  {
    id: API_ID,
    title: QUESTIONS.EXPLORE_API,
    responseLabel: (formattedDate: string) => `Here’s the TODO for ${formattedDate}:`,
    enabled: true,
    promptName: "outside_link",
  },
  {
    id: BOARD_MEETING_PREP_ID,
    title: QUESTIONS.WOUTER_QUESTION,
    responseLabel: (formattedDate: string) => `Here’s the response for ${formattedDate}:`,
    enabled: true,
    promptName: BOARD_MEETING_PREP_PROMPT,
  },
];

// TODO: REMOVE AFTER ACTIVATING BOARD PREP PROMPT
export const FAKE_MD_RESPONSE = `

# Wouter Born Board Prep

## Introduction
[Wouter Born](https://es.linkedin.com/in/wouterborn) is a Dutch entrepreneur and investor. He is the founder of the Born2Invest app, which provides users with the latest news and updates on the stock market, business, and finance. Born2Invest is available in multiple languages and has a global audience.


# H1
## H2
### H3

_italics_

**Bold**

***Bold Italics***

`;
