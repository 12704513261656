import { create } from "zustand";

export enum LaunchpadStep {
  TaskList = "TaskList",

  BookKeepingPlanSelection = "BookKeepingPlanSelection",
  BookKeepingPlanConfirmHave = "BookKeepingPlanConfirmHave",
  BookKeepingPlanConfirmWant = "BookKeepingPlanConfirmWant",
  BookKeepingPlanConfirmDIY = "BOokKeepingPlanConfirmDIY",

  AdvancedAccountingSettings = "AdvancedAccountingSettings",
}

type LaunchpadStore = {
  isLaunchpadOverlayVisible: boolean;
  showLaunchpadOverlay: () => void;
  hideLaunchpadOverlay: () => void;
  toggleLaunchpadOverlay: () => void;

  currentStep: LaunchpadStep;
  goToStep: (newStep: LaunchpadStep) => void;

  isColumnLayout: boolean;
  setIsColumnLayout: (isColumnLayout: boolean) => void;
};

export const useLaunchpadStore = create<LaunchpadStore>()((set) => ({
  isLaunchpadOverlayVisible: false,
  showLaunchpadOverlay: () =>
    set((state: LaunchpadStore) => ({ ...state, isLaunchpadOverlayVisible: true })),
  hideLaunchpadOverlay: () =>
    set((state: LaunchpadStore) => ({ ...state, isLaunchpadOverlayVisible: false })),
  toggleLaunchpadOverlay: () =>
    set((state: LaunchpadStore) => ({
      ...state,
      isLaunchpadOverlayVisible: !state.isLaunchpadOverlayVisible,
    })),

  currentStep: LaunchpadStep.TaskList,
  goToStep: (newStep: LaunchpadStep) =>
    set((state: LaunchpadStore) => ({ ...state, currentStep: newStep })),

  isColumnLayout: true,
  setIsColumnLayout: (isColumnLayout: boolean) =>
    set((state: LaunchpadStore) => ({ ...state, isColumnLayout: isColumnLayout })),
}));
