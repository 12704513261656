export type MonthEndResponseItem = {
  id: string;
  item: string;
  confidence: string;
  reason: string;
  recommendation: string;
};

export enum QUESTIONS {
  REVIEW_MONTH_END_CLOSE = "Review month-end close for accuracy",
  BUSINESS_STATUS_ELON = "Tell me about my business (as if you were Elon Musk)",
  BUSINESS_STATUS_INVESTOR = "Tell me about my business (as if you were a friendly investor)",
  BUSINESS_STATUS_FRIENDLY = "Tell me about my business (but please don’t hurt my feelings)",
  VALIDATE_BANK_RECONCILIATIONS = "Validate drafted bank reconciliations (coming soon)",
  EXPLORE_API = "Explore our API to build your own agent",
  WOUTER_QUESTION = "Wouter Born's Board Prep Prompt",
}

export type Question = {
  id: string;
  title: QUESTIONS;
  responseLabel: (formattedDate: string) => string;
  enabled: boolean;
  promptName: string;
};

export enum STEPS {
  /** Initial step containing the data sharing modal */
  WELCOME = "welcome",
  /** Step containing the list of prompts to choose from */
  PROMPT_LIST = "promptList",
  /** Step containing the results rendered */
  RESULTS = "results",
}
