import { create } from "zustand";
import { TargetType } from "./shortcuts/types";
import { useTransactionsTargetType } from "components/dashboard/Transactions/hooks/useTransactionsTargetType";

type CmdkState = {
  isCmdkOpen: boolean;
  setIsCmdkOpen: (val: boolean) => void;
  toggleCmdk: () => void;
  isTargetTypeAllowed: (targetType: keyof typeof TargetType) => boolean;
};

export const useCmdkStore = create<CmdkState>((set) => ({
  isCmdkOpen: false,
  setIsCmdkOpen: (val) => set({ isCmdkOpen: val }),
  toggleCmdk: () => set((state) => ({ isCmdkOpen: !state.isCmdkOpen })),
  isTargetTypeAllowed: (targetType: keyof typeof TargetType) => {
    switch (targetType) {
      case TargetType.TRANSACTION_ROW: {
        const { isAnyRowHovered, isAnyRowSelected, isAnyRowActive } =
          useTransactionsTargetType.getState();
        return isAnyRowHovered || isAnyRowSelected || isAnyRowActive;
      }
      default:
        return false;
    }
  },
}));
