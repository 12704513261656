import React, { useEffect, useState } from "react";

import { Menu, Avatar } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { zIndex } from "@puzzle/utils";

import Analytics from "lib/analytics/analytics";
import { useAppRouter } from "lib/useAppRouter";

import useSelf from "components/users/useSelf";
import { MenuButton } from "./MenuButtonStyles";
import useEffectOnRouteChange from "./useEffectOnRouteChange";

import { DATA_TEST_IDS } from "./constants";

const UserLabel = styled(Menu.Label, {
  // ah, variants are more specific
  "&&": {
    background: "transparent !important",
  },

  minWidth: 180,
  textVariant: "$bodyXS",
  color: "$gray400",

  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  padding: "$1 $2",
});

const UserName = styled("div", {
  textVariant: "$headingS",
  color: "$gray50",
});

export const ProfileMenu = ({
  minimized,
  showAllOptions = true,
}: {
  minimized: boolean;
  showAllOptions?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const { logout, goToNotificationSettings } = useAppRouter();
  const { self } = useSelf();

  useEffectOnRouteChange(() => setOpen(false));

  useEffect(() => {
    if (open) {
      Analytics.profileMenuOpened();
    }
  }, [open]);

  if (!self) return null;

  return (
    <Menu
      arrow={!minimized}
      side={minimized ? "right" : "bottom"}
      align={minimized ? "center" : "start"}
      sideOffset={minimized ? 24 : 4}
      modal={false}
      open={open}
      onOpenChange={setOpen}
      trigger={
        <MenuButton
          data-testid={DATA_TEST_IDS.PROFILE_MENU}
          minimized={minimized}
          css={{ zIndex: zIndex("profileMenu") }}
        >
          <Avatar user={{ email: self.email, name: self.name ?? "Unknown" }} />
        </MenuButton>
      }
      css={{ zIndex: 2 }}
    >
      <UserLabel>
        <UserName>{self.name}</UserName>
        <div>{self.email}</div>
      </UserLabel>

      <>
        <Menu.Separator />
        {showAllOptions && (
          <Menu.Group>
            <Menu.Item
              onSelect={() => {
                Analytics.notificationSettingsClicked({
                  location: "profile-menu",
                });
                goToNotificationSettings();
              }}
            >
              Notification settings
            </Menu.Item>
          </Menu.Group>
        )}
      </>
      <Menu.Separator />
      <Menu.Group>
        <Menu.Item onSelect={() => logout()}>Log out</Menu.Item>
      </Menu.Group>
    </Menu>
  );
};
