import { create } from "zustand";

type SkipTrialState = {
  isCheckingSkipTrial: boolean;
  setIsCheckingSkipTrial: (isCheckingSkipTrial: boolean) => void;
  isSkipTrialConfirmationDialogOpen: boolean;
  showSkipTrialConfirmationDialog: () => void;
  hideSkipTrialConfirmationDialog: () => void;
};

export const useSkipTrialStore = create<SkipTrialState>((set) => ({
  isCheckingSkipTrial: false,
  setIsCheckingSkipTrial: (isCheckingSkipTrial) => set({ isCheckingSkipTrial }),
  isSkipTrialConfirmationDialogOpen: false,
  showSkipTrialConfirmationDialog: () => set({ isSkipTrialConfirmationDialogOpen: true }),
  hideSkipTrialConfirmationDialog: () => set({ isSkipTrialConfirmationDialogOpen: false }),
}));
