export const BASE_DISALLLOWED_ELEMENTS = [
  "blockquote",
  "br",
  "code",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "hr",
  "img",
  "li",
  "ol",
  "pre",
  "ul",
  "del",
  "input",
  "table",
  "tbody",
  "td",
  "th",
  "thead",
  "tr",
];
export const BOLD_AND_ITALIC_ELEMENTS = ["strong", "em"];
