/*

  A SHORTCUT is a quick link to a specific page or action in the app.
  SHORTCUTS may or may not have HOTKEYS associated with them.

  SHORTCUTS can be fired by clicking on them when their UI is displayed
  or by pressing the HOTKEYS (if any) associated with them.

  SHORTCUTS take into account their context.
  If the user is on the Transactions page, they have Transactions-specific SHORTCUTS available to them.
  If the user is hovered over a specific row in the Transactions table, they have row-specific SHORTCUTS available to them.

  SHORTCUTS may also be dynamically generated.
  For example, users want the ability to switch between their companies quickly.
  So we must generate a SHORTCUT for each company the user has access to.

  HOTKEYS may also exist elsewhere in the app, independent of SHORTCUTS.
  We have presently assigned HOTKEYS to simple actions in some individual components.
  For example: J and K for navigating to previous and next items in a list. 
  These are not considered SHORTCUTS presently. (Though perhaps they should be.)

*/

import { helpShortcuts } from "./Help";
import { navigationShortcuts } from "./Navigation";
import { transactionShortcuts } from "./Transactions";
import type { ShortcutsGroup } from "./types";

export const SHORTCUTS_GROUPS: ShortcutsGroup[] = [
  {
    title: "Navigation",
    id: "navigation",
    shortcuts: navigationShortcuts,
  },
  {
    title: "Transactions",
    id: "transactions",
    shortcuts: transactionShortcuts,
    urlPattern: /\/transactions.*/, // Matches /transactions with any amount of characters after
  },
  {
    title: "Help",
    id: "help",
    shortcuts: helpShortcuts,
  },
];
