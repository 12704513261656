import keyBy from "lodash/keyBy";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import useSelf from "components/users/useSelf";
import { useUserLoadingCompaniesQuery } from "./graphql.generated";
import { useEffect, useMemo } from "react";
import { CompanyIngestStatus } from "graphql/types";
import { useActiveCompany } from "./ActiveCompanyProvider";
import { add } from "date-fns/add";
import { isBefore } from "date-fns/isBefore";
import { now } from "@internationalized/date";

const REFETCH_INTERVAL = 5000;
const MODERATE_TRANSACTION_COUNT_LIMIT = 10000;
const MODERATE_CUSTOMER_COUNT_LIMIT = 1000;
const HIGH_TRANSACTION_COUNT_LIMIT = 500000;
const HIGH_CUSTOMER_COUNT_LIMIT = 10000;
const MODERATE_TIME_THRESHOLD = 60 * 2 + 30;

export enum VolumeLevel {
  LOW = "low",
  MODERATE = "moderate",
  HIGH = "high",
}

type UseLoadingCompaniesProps = {
  volumeLevel: VolumeLevel;
};

const useHighVolumeCompany = (): UseLoadingCompaniesProps => {
  const { self } = useSelf();
  const { company: loadedCompany } = useActiveCompany<true>();
  const { data, stopPolling } = useUserLoadingCompaniesQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: REFETCH_INTERVAL,
    skip: loadedCompany.initialIngestStatus === CompanyIngestStatus.UpToDate,
  });

  const companies = useMemo(
    () => orderBy(data?.companies ?? [], (c) => c.stripeTransactionCount),
    [data?.companies]
  );
  const companiesById = useMemo(() => keyBy(companies, "id"), [companies]);

  const company = useMemo(() => {
    if (self?.company) {
      return companiesById[self.company.id];
    }
    // I wonder if there is a better way to do this...
    return last(companies);
  }, [companiesById, self, companies]);

  useEffect(() => {
    if (
      company?.initialIngestStatus === CompanyIngestStatus.UpToDate ||
      (company?.stripeTransactionCount !== 0 &&
        company?.stripeTransactionCount !== MODERATE_TRANSACTION_COUNT_LIMIT)
    ) {
      stopPolling();
    }
  }, [company, stopPolling]);

  const volumeLevel = useMemo(() => {
    if (company?.stripeTransactionCount && company.stripeCustomerCount) {
      if (
        company?.stripeTransactionCount >= HIGH_TRANSACTION_COUNT_LIMIT ||
        company.stripeCustomerCount >= HIGH_CUSTOMER_COUNT_LIMIT
      )
        return VolumeLevel.HIGH;
      if (
        company?.stripeTransactionCount >= MODERATE_TRANSACTION_COUNT_LIMIT ||
        company?.stripeCustomerCount >= MODERATE_CUSTOMER_COUNT_LIMIT ||
        (company.initialIngestDate &&
          isBefore(
            add(new Date(company.initialIngestDate), {
              seconds: MODERATE_TIME_THRESHOLD,
            }),
            now("UTC").toDate()
          ))
      )
        return VolumeLevel.MODERATE;
    }
    return VolumeLevel.LOW;
  }, [company]);

  return { volumeLevel };
};

export { useHighVolumeCompany };
