import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useLocalStorage } from "react-use";

import {
  Analysis,
  BarChart,
  Burn2,
  Calculator2,
  CheckList2,
  Dashboard2,
  Download2,
  Inbox2,
  Payroll,
  Settings2,
  Transactions,
  PlugOutline2,
} from "@puzzle/icons";

import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics/featureFlags";
import { ACCOUNTING_ROUTES, Route } from "lib/routes";
import { IS_LOCAL_DEVELOPMENT } from "lib/config";
import { useAppRouter } from "lib/useAppRouter";

import { useActiveCompany, PricingFeatures } from "components/companies/ActiveCompanyProvider";
import { Minimizer } from "./Minimizer";
import { ProfileMenu } from "./ProfileMenu";
import { CompanyMenu } from "./CompanyMenu";
import { NavSection, RootNavItem, SubNavItem, CmdKItem } from "./NavItems";
import {
  rootRecipe,
  listStyle,
  headerContainerStyle,
  headerStyle,
  contentContainerStyle,
  footerContainerStyle,
  footerListStyle,
} from "./sidebarV2.css";
import { TrialInfoPanel } from "./TrialInfoPanel/TrialInfoPanel";
import { AI_LABS_SECTION_TITLE } from "components/aiLabs/constants";
import { AlphaTag } from "components/aiLabs/components/AlphaTag";
import { MatchedSource } from "components/dashboard/Accounting/Payments/PaymentsQsContext";
import { MembershipRole } from "graphql/types";

import { DATA_TEST_IDS } from "./constants";

const DynamicPendingInboxItems = dynamic(() =>
  import("./PendingInboxItems").then((mod) => mod.PendingInboxItems)
);

type SidebarV2Props = {
  minimized: boolean;
  onToggleMinimized: (minimize: boolean) => void;
};
const NAV_ITEM_ICON_SIZE = 20;
const OPEN_SECTION_KEY = "pz:nav:open-section";

export const SidebarV2 = ({ minimized, onToggleMinimized }: SidebarV2Props) => {
  const { membershipRole } = useActiveCompany<true>();
  const [openSection, setOpenSection] = useLocalStorage(OPEN_SECTION_KEY, "none");
  const [isHeaderHovered, setIsHeaderHovered] = React.useState(false);
  const { pricePlanFeatureEnabled, company } = useActiveCompany<true>();
  const showCustomers = isPosthogFeatureFlagEnabled(FeatureFlag.CustomersPage);
  const showProducts = isPosthogFeatureFlagEnabled(FeatureFlag.ProductsPage);
  const showAILabs = company.features.aiInsightsEnabled;
  const showArPayments = company.features.arPaymentsEnabled || IS_LOCAL_DEVELOPMENT;
  const showApPayments = company.features.apPaymentsEnabled || IS_LOCAL_DEVELOPMENT;
  const router = useRouter();
  const { isFirmRoute } = useAppRouter();
  const hideCompanySpecificLinks = isFirmRoute(router.asPath);

  const toggleSection = (section: string) => {
    setOpenSection(section === openSection ? "none" : section);
  };

  return (
    <div data-testid={DATA_TEST_IDS.CONTAINER} className={rootRecipe({ minimized })}>
      <div className={headerContainerStyle}>
        <div
          className={headerStyle}
          onMouseEnter={() => setIsHeaderHovered(true)}
          onMouseLeave={() => setIsHeaderHovered(false)}
        >
          <Minimizer minimized={minimized} onToggle={onToggleMinimized} hovered={isHeaderHovered} />
          <ProfileMenu minimized={minimized} />
        </div>
        <CompanyMenu />
      </div>
      <div className={contentContainerStyle}>
        {/* When on the firm admin page, the user should have the experience that
         they do not have a company selected  */}
        {!hideCompanySpecificLinks && (
          <ul className={listStyle}>
            <RootNavItem
              data-testid={DATA_TEST_IDS.INBOX}
              title="Inbox"
              route={Route.inbox}
              icon={<Inbox2 size={NAV_ITEM_ICON_SIZE} />}
              suffix={<DynamicPendingInboxItems />}
            />

            <RootNavItem
              data-testid={DATA_TEST_IDS.DASHBOARD}
              title="Dashboard"
              route={Route.home}
              icon={<Dashboard2 size={NAV_ITEM_ICON_SIZE} />}
            />

            <RootNavItem
              data-testid={DATA_TEST_IDS.CHECKLIST}
              title="Checklists"
              route={Route.checklist}
              icon={<CheckList2 size={NAV_ITEM_ICON_SIZE} />}
            />

            <RootNavItem
              data-testid={DATA_TEST_IDS.TRANSACTIONS}
              title="Transactions"
              route={Route.transactions}
              icon={<Transactions size={NAV_ITEM_ICON_SIZE} />}
            />

            <NavSection
              data-testid={DATA_TEST_IDS.ANALYSIS}
              title="Analysis"
              icon={<Analysis size={NAV_ITEM_ICON_SIZE} />}
              routes={[Route.spending, Route.revenue, Route.people]}
              open={openSection === "analysis"}
              onClick={() => toggleSection("analysis")}
            >
              <SubNavItem
                data-testid={DATA_TEST_IDS.SPENDING}
                title="Spending"
                route={Route.spending}
                featureGated={
                  !pricePlanFeatureEnabled.has(PricingFeatures.spend_explorer_vendor_insights)
                }
              />
              <SubNavItem
                title="Revenue"
                data-testid={DATA_TEST_IDS.ANALYSIS_REVENUE}
                route={Route.revenue}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.PEOPLE}
                title="People"
                route={Route.people}
                featureGated={!pricePlanFeatureEnabled.has(PricingFeatures.people_insights)}
              />
              {showAILabs && (
                <SubNavItem
                  data-testid={DATA_TEST_IDS.AI_LABS}
                  title={AI_LABS_SECTION_TITLE}
                  route={Route.aiLabs}
                  suffix={<AlphaTag />}
                />
              )}
            </NavSection>

            {membershipRole !== MembershipRole.ClientViewer && (
              // will eventually want to handle this through access in VerifyRouteAccessProvider
              // but for the time being for Decimal launch removing this is just about
              // cleaning up the UI and not really about access control, if they happen upon
              // these pages somehow it's okay if we show them
              //
              // doing this through the access requires a few more changes due to how
              // some of the routes are set up currently with URLs not related to the
              // parent section they are in in the new side nav, product is aware of this
              // and may approve changing those URLs soon
              <NavSection
                data-testid={DATA_TEST_IDS.ACCOUNTING}
                title="Accounting"
                icon={<Calculator2 size={NAV_ITEM_ICON_SIZE} />}
                routes={ACCOUNTING_ROUTES.map((r) => r.path)}
                open={openSection === "accounting"}
                onClick={() => toggleSection("accounting")}
                sectionGap
              >
                {ACCOUNTING_ROUTES.map((r) => (
                  <SubNavItem
                    key={r.path}
                    title={r.displayText}
                    route={r.path}
                    data-testid={DATA_TEST_IDS[r.testIdKey]}
                  />
                ))}
              </NavSection>
            )}

            <NavSection
              data-testid={DATA_TEST_IDS.REVENUE}
              title="Revenue"
              icon={<BarChart size={NAV_ITEM_ICON_SIZE} />}
              routes={[Route.invoices, Route.customers, Route.arAging]}
              open={openSection === "revenue"}
              onClick={() => toggleSection("revenue")}
            >
              <SubNavItem
                data-testid={DATA_TEST_IDS.INVOICES}
                title="Invoices"
                route={Route.invoices}
              />
              {showCustomers && (
                <SubNavItem
                  data-testid={DATA_TEST_IDS.CUSTOMERS}
                  title="Customers"
                  route={Route.customers}
                />
              )}
              {showProducts && (
                <SubNavItem
                  data-testid={DATA_TEST_IDS.PRODUCTS}
                  title="Products"
                  route={Route.products}
                />
              )}
              <SubNavItem
                data-testid={DATA_TEST_IDS.REVENUE_RECOGNITION}
                title="Revenue recognition"
                route={Route.revenueRecognition}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.ACCRUAL_REVENUE}
                title="Accrual revenue"
                route={Route.accrualRevenue}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.AR_AGING}
                title="AR aging"
                route={Route.arAging}
              />
              {showArPayments && (
                <SubNavItem
                  data-testid={DATA_TEST_IDS.INVOICE_PAYMENTS}
                  title="Customer payments"
                  route={
                    `${Route.newInvoicePayment}&matchedSource=${MatchedSource.Invoice}` as Route
                  }
                  checkEntireRoute
                />
              )}
            </NavSection>

            <NavSection
              data-testid={DATA_TEST_IDS.EXPENSES}
              title="Expenses"
              icon={<Burn2 size={NAV_ITEM_ICON_SIZE} />}
              routes={[Route.bills, Route.vendors, Route.prepaidExpenses, Route.apAging]}
              open={openSection === "expenses"}
              onClick={() => toggleSection("expenses")}
            >
              <SubNavItem data-testid={DATA_TEST_IDS.BILLS} title="Bills" route={Route.bills} />
              <SubNavItem
                data-testid={DATA_TEST_IDS.VENDORS}
                title="Vendors"
                route={Route.vendors}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.PREPAID_EXPENSES}
                title="Prepaid expenses"
                route={Route.prepaidExpenses}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.AP_AGING}
                title="AP aging"
                route={Route.apAging}
              />
              {showApPayments && (
                <SubNavItem
                  data-testid={DATA_TEST_IDS.BILL_PAYMENTS}
                  title="Vendor payments"
                  route={`${Route.newBillPayment}&matchedSource=${MatchedSource.Bill}` as Route}
                  checkEntireRoute
                />
              )}
            </NavSection>

            <RootNavItem
              data-testid={DATA_TEST_IDS.PAYROLL}
              title="Payroll"
              route={Route.payroll}
              icon={<Payroll size={NAV_ITEM_ICON_SIZE} />}
            />

            <RootNavItem
              data-testid={DATA_TEST_IDS.DOWNLOADS}
              title="Downloads"
              route={Route.reports}
              icon={<Download2 size={NAV_ITEM_ICON_SIZE} />}
            />

            <RootNavItem
              data-testid={DATA_TEST_IDS.INTEGRATIONS}
              title="Integrations"
              route={Route.integrations}
              icon={<PlugOutline2 size={NAV_ITEM_ICON_SIZE} />}
              sectionGap
            />
            <NavSection
              data-testid={DATA_TEST_IDS.SETTINGS}
              title="Settings"
              icon={<Settings2 size={NAV_ITEM_ICON_SIZE} />}
              routes={[
                Route.companySettings,
                Route.billing,
                Route.automationSettings,
                Route.notificationSettings,
              ]}
              open={openSection === "settings"}
              onClick={() => toggleSection("settings")}
            >
              <SubNavItem
                data-testid={DATA_TEST_IDS.COMPANY}
                title="Company"
                route={Route.companySettings}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.BILLING}
                title="Billing"
                route={Route.billing}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.SETTINGS_ACCOUNTING}
                title="Accounting"
                route={Route.automationSettings}
              />
              <SubNavItem
                data-testid={DATA_TEST_IDS.NOTIFICATIONS}
                title="Notifications"
                route={Route.notificationSettings}
              />
            </NavSection>
          </ul>
        )}
      </div>
      <div className={footerContainerStyle}>
        {!minimized && <TrialInfoPanel />}
        <ul className={footerListStyle}>
          <CmdKItem />
        </ul>
      </div>
    </div>
  );
};
