import React, { useCallback } from "react";
import { styled } from "@puzzle/theme";
import { ChevronLeft, ChevronRight } from "@puzzle/icons";
import { useAppRouter } from "lib/useAppRouter";

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Crumb = styled("div", {
  display: "inline-flex",
  justifyContent: "center",
  color: "text.lightGrey",
  "&:hover": {
    textTransform: "underline",
  },
  defaultVariants: {
    disabled: false,
  },
  variants: {
    disabled: {
      false: {
        cursor: "pointer",
      },
    },
  },
});

const Icon = styled(ChevronRight, { margin: "$0 $1" });
const BackIcon = styled(ChevronLeft, { margin: "$0 $2" });

type Breadcrumb = {
  label: string;
  path?: string;
};

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
  onGoBack?: () => void; // should default behavior be pop?
};

export const Breadcrumbs = ({ breadcrumbs, onGoBack }: BreadcrumbsProps) => {
  const { router } = useAppRouter();
  const goBack = useCallback(() => {
    if (onGoBack) {
      return onGoBack();
    }

    if (breadcrumbs.length > 1) {
      const last = breadcrumbs[breadcrumbs.length - 2];
      if (last.path) {
        router.push(last.path);
      }
    }
  }, [breadcrumbs, onGoBack, router]);

  return (
    <Wrapper>
      <BackIcon onClick={goBack} height={26} width={26} />
      {breadcrumbs.map((b, i) => (
        <Crumb key={b.label} onClick={() => b.path && router.push(b.path)} disabled={!b.path}>
          {b.label}
          {i < breadcrumbs.length - 1 && <Icon />}
        </Crumb>
      ))}
    </Wrapper>
  );
};
