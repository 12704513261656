import merge from "lodash/merge";
import { useToasts } from "@puzzle/ui";
import {
  useConnectRipplingMutation,
  RipplingIntegrationDocument,
  useRipplingIntegrationQuery,
  RipplingIntegrationQuery,
  IntegrationType,
  CompanySettingFeatureName,
} from "graphql/types";
import { ConnectRipplingInput, UseRipplingResult } from "./types";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { saveRequestedIntegrationCookie } from "lib/cookies";
import { useSavePage } from "../shared/useSavePage";
import { useFinancialInstitutions } from "components/integrations/shared/FinancialInstitutionsProvider";
import { UseIntegrationHook } from "components/integrations/shared/types";
import { config } from "lib/config";
import Analytics from "lib/analytics/analytics";
import { useMemo } from "react";
import { useRipplingEmployeeCountForQuery } from "./graphql.generated";
import { useUpdateCompanyFeatureMutation } from "components/companies/graphql.generated";
import { useActiveCompany } from "components/companies/ActiveCompanyProvider";

export const useRippling: UseIntegrationHook<UseRipplingResult> = ({
  companyId,
  integrationConnectionId,
  onClickConnect: baseOnClickConnect,
}): UseRipplingResult => {
  const { toast } = useToasts();
  const { company } = useActiveCompany<true>();
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { data, loading, startPolling, stopPolling, refetch } = useRipplingIntegrationQuery({
    variables: {
      companyId,
    },
  });

  const { integrations } = data || {};
  const { integrationConnections, disconnectIntegrationConnection } = useFinancialInstitutions();
  const [connectRipplingMutation, { loading: connecting, error: connectionError }] =
    useConnectRipplingMutation();
  const [updateCompanyFeature] = useUpdateCompanyFeatureMutation();

  const { savePage } = useSavePage();

  const initializationInfo = integrations?.rippling.initializationInfo;

  const connection = useMemo(() => {
    // Use the first/only connection for now, more coming
    // returns undefined if integrationConnectionId is undefined
    const connectionId = integrationConnectionId ?? integrations?.rippling.connections?.[0]?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrationConnectionId, integrations?.rippling.connections]);

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();
    saveRequestedIntegrationCookie(null, IntegrationType.Rippling);

    if (!initializationInfo) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "useRippling's onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectRippling = async (input: ConnectRipplingInput) => {
    addPendingConnection(IntegrationType.Rippling);

    return connectRipplingMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;
        const ripplingIntegration = cache.readQuery<RipplingIntegrationQuery>({
          query: RipplingIntegrationDocument,
          variables: {
            companyId,
          },
        });

        const allConnections = ripplingIntegration?.integrations.rippling.connections;
        cache.writeQuery({
          query: RipplingIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, ripplingIntegration, {
            integrations: {
              __typename: "Integrations",
              rippling: {
                __typename: "RipplingIntegration",
                connections: allConnections
                  ? [...allConnections, data.connectRippling.connection]
                  : [],
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectRippling;
        removePendingConnection(IntegrationType.Rippling);
        updateCompanyFeature({
          variables: {
            input: {
              companyId,
              featureName: CompanySettingFeatureName.RipplingLegacyApi,
              value: false,
            },
          },
        });

        // Users will be connected but ingestion will not begin until they are on a paid plan
        toast({
          message: `You're successfully connected to Rippling.`,
          status: "success",
        });

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Rippling",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Rippling",
          reason: message,
        });
      },
    });
  };

  const disconnectIntegration = useMemo(() => {
    const isRipplingLegacyApi = company?.features.ripplingLegacyApi;

    const connectionId = connection?.id;
    if (!connectionId) {
      return undefined;
    }

    return async () => {
      const result = disconnectIntegrationConnection(connectionId);

      if (!integrationConnectionId) {
        refetch();
      }

      if (isRipplingLegacyApi) {
        updateCompanyFeature({
          variables: {
            input: {
              companyId,
              featureName: CompanySettingFeatureName.RipplingLegacyApi,
              value: false,
            },
          },
        });
      }

      return result;
    };
  }, [
    disconnectIntegrationConnection,
    integrationConnectionId,
    connection?.id,
    refetch,
    company?.features.ripplingLegacyApi,
  ]);

  return {
    connectionError,
    connectRippling,
    loading,
    disconnect: disconnectIntegration,
    connecting,
    onClickConnect,
    connection,
    refetch,
    startPolling,
    stopPolling,
  };
};

// TODO: should make this a lazy query to avoid unnecessary requests
export const useRipplingEmployeeCount = ({
  companyId,
  skip,
}: {
  companyId: string;
  skip: boolean;
}) => {
  const { toast } = useToasts();

  const { data } = useRipplingEmployeeCountForQuery({
    variables: {
      companyId,
    },
    skip,
    onError: () => {
      toast({
        message:
          "There was an error fetching the Rippling employee count, your estimate may be inaccurate",
      });
    },
  });

  const ripplingEmployeeCount = data?.ripplingEmployeeCountFor ?? 0;

  return { ripplingEmployeeCount };
};
