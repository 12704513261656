import React from "react";
import { queryTypes, useQueryStates } from "next-usequerystate";
import { useForm } from "react-hook-form";
import { CashFlow } from "graphql/types";

export type SplitInput = {
  amount: string;
  descriptor: string;
  ledgerCoaKey: string;
};

export enum MatchedSource {
  Invoice = "Invoice",
  Customer = "Customer",
  Bill = "Bill",
  Vendor = "Vendor",
}

export enum PaymentType {
  Invoice = "Invoice",
  Bill = "Bill",
}

const matchedSourceToCashFlow: Record<PaymentType, CashFlow> = {
  [PaymentType.Invoice]: CashFlow.MoneyIn,
  [PaymentType.Bill]: CashFlow.MoneyOut,
};

const usePaymentsQsContextValue = () => {
  const [qsState, setQsState] = useQueryStates(
    {
      selectedTransactionId: queryTypes.string,
      isFromTransaction: queryTypes.boolean,
      matchedSource: queryTypes
        .stringEnum<MatchedSource>(Object.values(MatchedSource))
        .withDefault(MatchedSource.Invoice),
      paymentType: queryTypes
        .stringEnum<PaymentType>(Object.values(PaymentType))
        .withDefault(PaymentType.Invoice),
    },
    {
      history: "replace",
    }
  );

  const overpaymentForm = useForm<SplitInput>({
    mode: "onChange",
    defaultValues: {},
  });

  return {
    qsState,
    setQsState,
    cashFlow: matchedSourceToCashFlow[qsState.paymentType],
    overpaymentForm,
  };
};

const PaymentsQsContext = React.createContext<ReturnType<typeof usePaymentsQsContextValue> | null>(
  null
);

export const usePaymentsQsContext = () => {
  const context = React.useContext(PaymentsQsContext);

  if (context === null) {
    throw new Error("usePaymentsQsContext must be used as a child within InvoicesContextProvider");
  }

  return context;
};

export const PaymentsQsContextProvider = ({ ...props }) => {
  return <PaymentsQsContext.Provider value={usePaymentsQsContextValue()} {...props} />;
};
