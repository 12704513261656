import React from "react";
import { Categorize } from "@puzzle/icons";
import { CmdkShortcutId, OnSelectActions, ShortcutWithStaticId, TargetType } from "./types";

/*
  Order is important in this array.
  More specific shortcuts should be placed before more general ones.
  E.g. A shortcut for changing a specific transaction should appear before a shortcut for creating a rule for all transactions.
  Remember, shortcuts will only be displayed if their urlPattern matches the current url (or it has no urlPattern).
*/

export const transactionShortcuts: ShortcutWithStaticId[] = [
  {
    id: CmdkShortcutId.SET_OR_CHANGE_CATEGORY,
    icon: <Categorize />,
    description: "Categorize",
    actionOnSelect: OnSelectActions.OPEN_BULK_RECATEGORIZE_MODAL,
    targetType: TargetType.TRANSACTION_ROW,
    urlPattern: /\/transactions.*/, // Matches /transactions with any amount of characters after
    hotkey: {
      keys: ["C"],
      trigger: "C",
      shouldDisableOnInputs: true,
    },
  },
];
