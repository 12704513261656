import * as Types from '../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RipplingEmployeeCountForQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
}>;


export type RipplingEmployeeCountForQuery = { __typename?: 'Query', ripplingEmployeeCountFor?: number | null, ripplingProvisionedEmployeeCountFor?: number | null };


export const RipplingEmployeeCountForDocument = gql`
    query ripplingEmployeeCountFor($companyId: ID!) {
  ripplingEmployeeCountFor(companyId: $companyId)
  ripplingProvisionedEmployeeCountFor(companyId: $companyId)
}
    `;

/**
 * __useRipplingEmployeeCountForQuery__
 *
 * To run a query within a React component, call `useRipplingEmployeeCountForQuery` and pass it any options that fit your needs.
 * When your component renders, `useRipplingEmployeeCountForQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRipplingEmployeeCountForQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useRipplingEmployeeCountForQuery(baseOptions: Apollo.QueryHookOptions<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables> & ({ variables: RipplingEmployeeCountForQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>(RipplingEmployeeCountForDocument, options);
      }
export function useRipplingEmployeeCountForLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>(RipplingEmployeeCountForDocument, options);
        }
export function useRipplingEmployeeCountForSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>(RipplingEmployeeCountForDocument, options);
        }
export type RipplingEmployeeCountForQueryHookResult = ReturnType<typeof useRipplingEmployeeCountForQuery>;
export type RipplingEmployeeCountForLazyQueryHookResult = ReturnType<typeof useRipplingEmployeeCountForLazyQuery>;
export type RipplingEmployeeCountForSuspenseQueryHookResult = ReturnType<typeof useRipplingEmployeeCountForSuspenseQuery>;
export type RipplingEmployeeCountForQueryResult = Apollo.QueryResult<RipplingEmployeeCountForQuery, RipplingEmployeeCountForQueryVariables>;