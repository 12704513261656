import React, { ReactNode } from "react";
import { useUpdate } from "react-use";
import dynamic from "next/dynamic";

import { Button, SiteStatusMessage, SiteStatusMessageProps } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { Arrow } from "@puzzle/icons";

import { Route } from "lib/routes";
import { useAppRouter } from "lib/useAppRouter";

import { dismissSiteStatusMessage, isSiteStatusMessageDismissed } from "lib/cookies";

import { useSendEmailVerificationCodeMutation } from "graphql/types";
import { useSiteStatusMessagesQuery } from "graphql/queries/siteStatusMessages.generated";

import useSelf from "components/users/useSelf";
import { ExpensesExceededStatusMessage } from "components/featureGate/ExpensesExceededStatusMessage";
import { BASE_DISALLLOWED_ELEMENTS } from "components/common/MarkdownMessage/constants";

const DynanicMarkdownMessage = dynamic(() =>
  import("components/common/MarkdownMessage/MarkdownMessage").then((mod) => mod.MarkdownMessage)
);

const DRAWER_WIDTH = 280;
const MINIMIZED_DRAWER_WIDTH = 72;

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",

  variants: {
    minimized: {
      true: {
        left: MINIMIZED_DRAWER_WIDTH,
      },
      false: {
        left: DRAWER_WIDTH,
      },
    },
  },

  defaultVariants: {
    minimized: false,
  },

  "@media print": {
    display: "none",
  },
});

const VerificationBanner = styled("div", {
  textVariant: "$bodyM",
  fontFamily: "var(--fonts-inter)",
  fontSize: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  px: "$2",
  py: "$1",
  width: "inherit",
  maxWidth: "inherit",
  height: 40,
  color: "#1e54b7",
  backgroundColor: "#eff3fe",
});

const BannerButton = styled(Button, {
  "&&": {
    borderColor: "#1E54B7 !important",
    color: "#467CDF",
    "&:hover, &:focus": {
      color: "#5A90F3",
    },
  },
});

type GlobalBannerProps = {
  children?: ReactNode;
  minimized: boolean;
};

export const GlobalBanner = ({ children, minimized }: GlobalBannerProps) => {
  const { goToEmailVerification } = useAppRouter();
  const forceUpdate = useUpdate();

  const { self } = useSelf();
  const [sendEmailVerificationCode] = useSendEmailVerificationCodeMutation();
  const showEmailVerificationMessage = !self?.emailVerified;

  // TODO: Review with Product whether we should lazy load this information
  // https://linear.app/puzzlefin/issue/PER-286/explore-lazy-loading-non-urgent-features
  const { data: siteStatusMessagesData } = useSiteStatusMessagesQuery();
  const showSiteStatusMessages =
    siteStatusMessagesData && siteStatusMessagesData.siteStatusMessages.length > 0;

  if (!showEmailVerificationMessage && !showSiteStatusMessages) {
    return <>{children}</>;
  }

  return (
    <Wrapper minimized={minimized}>
      <ExpensesExceededStatusMessage />

      {/* Email verification banner */}
      {showEmailVerificationMessage && (
        <VerificationBanner>
          Verify your email and secure your account
          <BannerButton
            variant="secondary"
            size="mini"
            suffix={<Arrow fill="#5A90F3" />}
            href={Route.emailVerification}
            onClick={() => {
              sendEmailVerificationCode();
              goToEmailVerification();
            }}
          >
            Verify
          </BannerButton>
        </VerificationBanner>
      )}

      {/* Manually set / Site wide messages and announcements */}
      {siteStatusMessagesData?.siteStatusMessages.map((message) => {
        const dismissed = isSiteStatusMessageDismissed(message.id);
        if (dismissed) {
          return null;
        }

        return (
          <SiteStatusMessage
            key={message.id}
            type={message.type.toLowerCase() as unknown as SiteStatusMessageProps["type"]}
            onClose={() => {
              dismissSiteStatusMessage(message.id);
              forceUpdate();
            }}
          >
            <DynanicMarkdownMessage disallowedElements={BASE_DISALLLOWED_ELEMENTS}>
              {message.message}
            </DynanicMarkdownMessage>
          </SiteStatusMessage>
        );
      })}

      <div>{children}</div>
    </Wrapper>
  );
};
