import { PuzzleSubscriptionFragment } from "components/companies/graphql.generated";
import { differenceInDays } from "date-fns/differenceInDays";
import { Money } from "graphql/types";

const PROD_FREE_TRIAL_DAYS = 14;

export const FORMATION_SPENDING_LIMIT = 15000;

export const getAveragePercentage = (
  expenses: number,
  limit: number = FORMATION_SPENDING_LIMIT
) => {
  const progress =
    expenses > limit
      ? Math.max(0, (1 - (expenses - limit) / limit) * 100)
      : Math.max(0, (expenses / limit) * 100);
  return {
    progress,
    exceeding: expenses > limit,
  };
};

export function getFreeTrialValues(freeTrialStartDate: Date, freeTrialExpirationDate: Date) {
  const totalDaysOnFreeTrial = differenceInDays(freeTrialExpirationDate, freeTrialStartDate);
  const daysPassedOnFreeTrial = differenceInDays(new Date(), freeTrialStartDate);
  const daysLeftOnFreeTrial = totalDaysOnFreeTrial - daysPassedOnFreeTrial;
  const percentage = (daysPassedOnFreeTrial / totalDaysOnFreeTrial) * 100;
  return {
    percentage,
    daysLeftOnFreeTrial,
    totalDaysOnFreeTrial,
  };
}

export const getFreeTrialMessage = (daysLeftOnFreeTrial: number) => {
  if (daysLeftOnFreeTrial === 0) {
    return "Your trial expires today";
  }
  // For Puzzle companies, we are setting the Premium Free Trial end date to 10 years
  // after the company's creation date. So, we are limiting it to 14 days to be displayed.
  const totalDays = Math.min(daysLeftOnFreeTrial, PROD_FREE_TRIAL_DAYS);
  return `${formatDaysLeftFreeTrial(totalDays)} on your free trial`;
};

export const getShowUpgradeModal = (
  activePlan: PuzzleSubscriptionFragment,
  billedPlan: PuzzleSubscriptionFragment | null | undefined,
  hasAvailableUpgrade: boolean
) => {
  if (!hasAvailableUpgrade) {
    return false;
  }
  const isOnFreeTrial = activePlan.plan.isTrial;

  if (!isOnFreeTrial) {
    return true;
  }

  if (isOnFreeTrial && (!billedPlan || billedPlan?.plan?.isFree)) {
    return true;
  }

  return false;
};

export const getPlanPrice = (
  subscription: PuzzleSubscriptionFragment
): Money | undefined | null => {
  if (subscription.plan.isTrial || subscription.plan.isFree) {
    return { amount: "0", currency: "USD" };
  }

  if (subscription.price) {
    return subscription.price;
  }

  return null;
};

export const formatDaysLeftFreeTrial = (daysLeftOnFreeTrial: number) => {
  return `${daysLeftOnFreeTrial} day${daysLeftOnFreeTrial > 1 ? "s" : ""} left`;
};
