import { parseDate, today } from "@internationalized/date";
import { CalendarView, startOfCalendarDate } from "@puzzle/utils";
import { PuzzleSubscriptionStateFragment } from "components/companies/graphql.generated";
import { differenceInCalendarDays } from "date-fns/differenceInCalendarDays";
import { isAfter } from "date-fns/isAfter";
import { IS_PROD } from "lib/config";

const TOTAL_YEAR_DAYS = 365;

export const getDaysLeftOnFreeTrial = (subscriptionState: PuzzleSubscriptionStateFragment) => {
  const { activePlan, billedPlan } = subscriptionState;
  const isOnPaidPlan = billedPlan && !billedPlan?.plan.isFree;
  const isOnFreeTrial = activePlan?.plan.isTrial;

  if (!isOnFreeTrial || isOnPaidPlan || !activePlan?.endDate) {
    return null;
  }

  const todayDate = startOfCalendarDate(today("UTC"), CalendarView.Day, "UTC").toDate("UTC");
  const endDate = parseDate(activePlan?.endDate).toDate("UTC");

  if (isAfter(todayDate, endDate)) {
    return null;
  }

  const daysLeftOnFreeTrial = differenceInCalendarDays(endDate, todayDate);
  // If the end date on the premium free trial plan is null (sometimes it is),
  // or if we are in production and the premium free trial plan has an end date of
  // more than one year, we should hide this panel. (For Puzzle companies,
  // we are setting the Premium Free Trial end date to 10 years after the company's
  // creation date)
  if (IS_PROD && daysLeftOnFreeTrial > TOTAL_YEAR_DAYS) {
    return null;
  }

  return daysLeftOnFreeTrial;
};
