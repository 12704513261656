import { useCallback, useMemo } from "react";
import { useToasts } from "@puzzle/ui";

import { useActiveCompany } from "components/companies/ActiveCompanyProvider";
import useSelf from "components/users/useSelf";

import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import { useUpdateOnboardingStepMutation } from "components/companies/graphql.generated";
import { OnboardingStepStatus, OnboardingStep, CompanyOrgType, CoaType } from "graphql/types";
import { useAppRouter } from "lib/useAppRouter";
import { Route } from "lib/routes";

const useUpdateCompanyOnboardingStep = () => {
  const [updateOnboardingStep] = useUpdateOnboardingStepMutation();
  const { company } = useActiveCompany<true>();
  const { toast } = useToasts();
  return {
    mutation: useCallback(
      (step: OnboardingStep, status: OnboardingStepStatus) => {
        return updateOnboardingStep({
          variables: {
            input: {
              companyId: company.id,
              step,
              status,
            },
          },

          onError() {
            toast({ status: "warning" });
          },
        });
      },
      [updateOnboardingStep, toast, company]
    ),
  };
};

export const STEP_REQUIRED = {
  configureAccountSettings: false,
  connectFinancialAccounts: false,
  getPuzzleCertified: false,
  inviteTeam: false,
  reviewMonthEndChecklist: true,
  reviewTransaction: true,
  setBookkeepingPlan: true,
  verifyBankBalance: true,
  reviewSpotlightVariances: false,
};
export const STEP_COUNT = Object.keys(STEP_REQUIRED).length;

const DASHBOARD_LAUNCHPAD_BANNER_SEEN = "pz:dashboard-launchpad-banner-seen";

export const countNumCompletedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.values(onboardingProgress).filter(
    (status) => status === OnboardingStepStatus.Completed
  ).length;
};

export const countNumSkippedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.values(onboardingProgress).filter(
    (status) => status === OnboardingStepStatus.Skipped
  ).length;
};

export const countOverallCompletedSteps = (
  onboardingProgress: Record<string, OnboardingStepStatus | null | undefined>
) => {
  return Object.keys(onboardingProgress).filter((step) => {
    if (onboardingProgress[step] === OnboardingStepStatus.Completed) {
      return true;
    }
    if (
      !STEP_REQUIRED[step as keyof typeof STEP_REQUIRED] &&
      onboardingProgress[step] === OnboardingStepStatus.Skipped
    ) {
      return true;
    }
    return false;
  }).length;
};

export const useOnboardingProgress = () => {
  const { company } = useActiveCompany<true>();
  const { mutation } = useUpdateCompanyOnboardingStep();
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();
  const { messageSettings, loading: messageSettingsLoading } = useSelf();
  const { getCurrentRoute } = useAppRouter();
  const isHomePage = Route.home == getCurrentRoute();
  const { __typename: _, ...onboardingProgress } = company?.attributes?.onboardingProgress ?? {}; // eslint-disable-line  @typescript-eslint/no-unused-vars

  const showScheduleOnboardingCall =
    company?.orgType &&
    [CompanyOrgType.CCorporation, CompanyOrgType.Llc].includes(company?.orgType);

  const hasDecimalCoaType = company?.coaType === CoaType.Decimal; // Never show for Decimal

  const { completionFraction, skipFraction, showLaunchbarButton } = useMemo(() => {
    const completionFraction = countNumCompletedSteps(onboardingProgress) / STEP_COUNT;
    const skipFraction = countNumSkippedSteps(onboardingProgress) / STEP_COUNT;
    const showLaunchbarButton =
      !hasDecimalCoaType && countOverallCompletedSteps(onboardingProgress) < STEP_COUNT;
    return { completionFraction, skipFraction, showLaunchbarButton };
  }, [company?.attributes?.onboardingProgress]);

  const dismissLaunchpadBanner = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: DASHBOARD_LAUNCHPAD_BANNER_SEEN,
          value: true,
        },
      },
    });
  };

  const showLaunchpadBanner =
    !hasDecimalCoaType &&
    !messageSettingsLoading &&
    !messageSettings?.[DASHBOARD_LAUNCHPAD_BANNER_SEEN];

  return {
    showScheduleOnboardingCall,
    showLaunchbarButton: showLaunchbarButton && (!showLaunchpadBanner || !isHomePage),
    completionFraction,
    skipFraction,
    onboardingProgress,
    updateOnboardingStep: mutation,
    showLaunchpadBanner,
    dismissLaunchpadBanner,
  };
};
