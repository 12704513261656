import React from "react";
import Image from "next/image";

import { Tooltip } from "@puzzle/ui";
import Analytics from "lib/analytics/analytics";

import { Text, CircularProgress } from "ve";

import { useLaunchpadStore } from "components/launchpad/launchpadStore";
import { LaunchpadSource } from "components/launchpad/analytics";
import { useOnboardingProgress } from "components/launchpad/useOnboardingProgress";

import rocket from "public/images/launchpad/rocket.svg?url";

import { buttonStyle } from "./onboardingLaunchpadButton.css";

export const OnboardingLaunchpadButton = () => {
  const { showLaunchpadOverlay } = useLaunchpadStore();
  const { showLaunchbarButton, completionFraction, skipFraction } = useOnboardingProgress();

  if (!showLaunchbarButton) {
    return null;
  }

  return (
    <Tooltip
      content="You can launch your setup checklist at any time from here."
      side="bottom"
      style={{ maxWidth: "153px" }}
    >
      <button
        className={buttonStyle}
        type="button"
        onClick={() => {
          Analytics.launchpadViewed({ Source: LaunchpadSource.TopNavBar });
          showLaunchpadOverlay();
        }}
      >
        <Image src={rocket} alt="rocket" width={16} height={16} />
        <Text variant="bodyS" weight="bold" color="white60">
          Setup guide
        </Text>
        <CircularProgress progress={completionFraction} progressSecondary={skipFraction} />
      </button>
    </Tooltip>
  );
};
